<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmQuizFormData && Object.keys(vmQuizFormData).length > 0">
              <div class="form-row">
                <div class="mt-4">
                    <h4 class="mb-3">
                      {{ cvPBACoverPic }} : {{ imageSize }}
                    </h4>
                    <div class="form-group row align-items-center">
                      <div class="col-md-12">
                        <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                          <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                          <i v-else class="fas fa-calendar d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                          <label class="p-image">
                            <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                            <input class="file-upload" type="file" required accept="image/png image/jpeg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                          </label>
                        </div>
                      </div>
                      <span class="text-danger" ref="coverPicError"></span>
                    </div>
                  </div>
                <div class="col-md-12 mb-3">
                  <label for="validationexam_name">
                    {{ cvExamNameLabel }}</label>
                    <ValidationProvider
                    name="Quiz Name"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuizFormData.app_name" type="text" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="app_child">
                  Chain this app to</label>
                    <select  id="app_child" class="form-control" v-model="vmQuizFormData.chain_to">
                      <option :value="app.app_id" v-for="(app, index) of chainableApps" :key="'chainable' + index">{{app.app_name}}</option>
                    </select>
                </div>
                <div class="col-md-12 mb-3">
                <label for="validationexam_name">
                    {{ cvExamTypeLabel }}</label>
                    <ValidationProvider
                    name="Quiz Type"
                    rules="required"
                    v-slot="{ errors }">
                    <select class="form-control" v-model="vmQuizFormData.app_type" disabled>
                    <option v-for="(Exam_type, index) in QuizType" :key="(index+1)" :value="index" >
                      {{ index }}
                    </option>
                    </select>
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationexam_description">
                    {{ cvExamDescriptionLabel }}</label>
                    <ValidationProvider
                    name="Quiz Description"
                    rules="required"
                    v-slot="{ errors }">
                  <vue-editor v-model="vmQuizFormData.app_description"></vue-editor>
                  <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationexam_category">
                    {{ cvExamCategoryLabel }}</label><br/>
                    <select v-model="vmQuizFormData.exam_category_option" class="form-control" @change="updateCategoryCount++">
                     <option disabled value="">Please select one</option>
                     <option v-for="item in vmqbkExamCategoryList" :key="item.app_category">{{ item.app_category }}</option>
                     <option value="AddNew">Add New</option>
                  </select>
                  <div :key="updateCategoryCount" v-if="vmQuizFormData.exam_category_option ==='AddNew' " style="margin-top: 5px" >
                  <input v-model="vmQuizFormData.app_category" placeholder="Enter the question Exam category" type="text" class="form-control" required />
                </div>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationexam_date">
                    {{ cvExamIsGidePlusLabel }}</label>
                    <ValidationProvider
                      name="Is this is a GidePlus Quiz ?"
                      rules="required"
                      v-slot="{ errors }">
                      <multiselect
                        v-model="app_is_gideplus"
                        :options="cvExamIsGidePlusOptions"
                        track-by="name"
                        label="name">
                      </multiselect>
                      <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3" v-if="vmQuizFormData.app_type == QuizType.COMPETITION">
                  <label for="validationexam_date">
                    {{ cvExamDateLabel }}</label>
                      <datetime
                      type="datetime"
                      v-model="vmQuizFormData.exam_date"
                      input-class="gide_datetime_input"
                      placeholder="Date month year at time"
                      class="theme-primary"
                      :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'}"
                      :phrases="{ok: 'Continue', cancel: 'Exit'}"
                      :hour-step="1"
                      :minute-step="5"
                      :week-start="7"
                      use12-hour
                      auto
                      :min-datetime="vmStartDate ? vmStartDate : minStartDate"
                      required>
                      </datetime>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationexam_date">
                    {{ cvExamDurationLabel }}</label>
                    <ValidationProvider
                      name="Quiz Duration (in seconds)"
                      rules="required"
                      v-slot="{ errors }">
                    <input v-model="vmQuizFormData.app_duration" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                  </div>
                <div class="col-md-12 mb-3">
                  <label for="validationexam_date">
                    {{ cvExamTotalQuestion }}</label>
                    <ValidationProvider
                    name="Set Total Questions in this Quiz"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuizFormData.app_total_questions" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>

                <div class="col-md-12 mb-3" v-if="vmQuizFormData.app_type == QuizType['TEST_PREP'].toUpperCase()">
                  <label for="validationexam_date">
                    {{ cvQuestionBatchNo }}</label>
                    <ValidationProvider
                    name="Set The batch No. The Question Belongs To"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuizFormData.batch_no" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationexam_date">
                    {{ cvExamTotalScore }}</label>
                    <ValidationProvider
                    name="Set Quiz Total Score"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuizFormData.app_total_score" type="number" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3" gide_inline_textorcheckbox>
                  <label for="validationexam_date">
                    {{ cvExamPassingScore }}</label>
                    <ValidationProvider
                    name="Set Quiz Passing Score"
                    rules="required"
                    v-slot="{ errors }">
                    <input v-model="vmQuizFormData.app_passing_score" type="text" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="is_ques_shuffled" v-model="vmQuizFormData.is_ques_shuffled" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="is_ques_shuffled">{{ cvShuffle }}</label>
                </div>
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="has_timer" v-model="vmQuizFormData.has_timer" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="has_timer">{{ cvConfigureTimer }}</label>
                </div>
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <!-- Show Leaderboard -->
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_leaderboard" v-model="vmQuizFormData.show_leaderboard" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_leaderboard">{{ cvShowLeaderboard }}</label>
                </div>
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <!-- Show Play Again -->
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_playagain" v-model="vmQuizFormData.show_playagain" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_playagain">{{ cvShowPlayAgain }}</label>
                </div>
                <!-- Show rank -->
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_rank" v-model="vmQuizFormData.show_rank" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_rank">{{ cvShowRank }}</label>
                </div>
                <!-- Show Analysis -->
                <div class="col-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_analysis" v-model="vmQuizFormData.show_analysis" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_analysis">{{ cvShowAnalysis }}</label>
                </div><!-- Show Analysis -->
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <!-- Show Results -->
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="show_results" v-model="vmQuizFormData.show_results" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="show_results">{{ cvShowResults}}</label>
                </div>
                <!-- Redirect to thank you page -->
                <div class="col-md-12 mb-3 gide_inline_textorcheckbox">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input id="redirect_to_thankyou_page" v-model="vmQuizFormData.redirect_to_thankyou_page" type="checkbox" class="form-control" required />
                    <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider>
                  <label class="lable ml-2" for="redirect_to_thankyou_page">{{ cvRedirectToThankyouPage}}</label>
                </div><!-- Redirect to thank you page -->
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2 gide_inline_textorcheckbox" v-if="vmQuizFormData.app_type == QuizType.COMPETITION">
                  <label class="lable" for="validationexam_name">
                    {{ cvExamIsPaid }}</label>
                  <ValidationProvider
                    name="Is this a Paid Quiz ?"
                    rules="required"
                    v-slot="{ errors }">
                  <input v-model="app_is_paid" type="checkbox" class="form-control" required />
                  <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider><br>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 mb-2 gide_inline_textorcheckbox">
                  <label class="lable" for="validationexam_date">
                    {{ cvExamIsOneTime }}</label>
                  <ValidationProvider
                    name="Is this a One-Time Quiz ?"
                    rules="required"
                    v-slot="{ errors }">
                  <input v-model="app_is_one_time" type="checkbox" class="form-control" required />
                  <span class="text-danger"> {{ errors[0] }} </span>
                  </ValidationProvider><br>
                </div> -->
              </div>
              <div class="row spinner_add">
                <div class="col-7">
                  <button type="button" class="btn btn-primary" @click="appEdit()">
                    {{ cvSubmitBtn }}
                  </button>
                </div>
                <div class="col-5">
                  <button  v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning"></b-spinner>
                  </button>
                </div>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
.gide_datetime_input {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  height: 45px;
  cursor: pointer;
  padding-left: 10px;
  color: #a09e9e;
  width: 100%;
}
.theme-primary .vdatetime-popup__header,
.theme-primary .vdatetime-calendar__month__day--selected > span > span,
.theme-primary .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #000000;
}

.theme-primary .vdatetime-year-picker__item--selected,
.theme-primary .vdatetime-time-picker__item--selected,
.theme-primary .vdatetime-popup__actions__button {
  color: #000000;
}
</style>
<script>
import { Apps } from "../../../FackApi/api/apps.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { ValidationProvider } from "vee-validate"
import QuizType from "../../../FackApi/json/QuizType.js"
import { Datetime } from "vue-datetime"
import CropImage from "../../../components/cropImage.vue"
import { VueEditor } from "vue2-editor"
import Multiselect from "vue-multiselect"
import moment from "moment"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "AppEdit",
  components: {
    VueEditor,
    ValidationProvider,
    Datetime,
    CropImage,
    Multiselect
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propQuizObj: {
      type: Object,
      default: function () {
        return {
          "app_name": "",
          "app_description": "",
          "app_category": "",
          "app_date_ts": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "app_edit",
      cvCardTitle: "Edit Fun App",
      cvSubmitBtn: "Edit",
      cvExamNameLabel: "Quiz Name",
      cvExamTypeLabel: "Quiz Type",
      cvExamDescriptionLabel: "Quiz Description",
      cvExamCategoryLabel: "Quiz Category",
      cvExamIsGidePlusLabel: "Quiz Is Gideplus ?",
      cvExamDateLabel: "Quiz Date",
      cvExamDurationLabel: "Quiz Duration(in second))",
      cvExamTotalQuestion: "Total Questions in this quiz",
      cvQuestionBatchNo: "Batch No. the question belongs to",
      cvExamTotalScore: "Quiz Total Score (100, 50 ,20)",
      cvExamPassingScore: "Quiz Passing Score",
      cvShuffle: "Show questions shuffled ?",
      cvShowAnalysis: "Show Analysis",
      cvConfigureTimer: "Show timer for this Quiz ?",
      cvShowLeaderboard: "Show Leaderboard",
      cvShowPlayAgain: "Show Play Again Button",
      cvShowRank: "Show Rank",
      cvRedirectToThankyouPage: "Redirect to Thank You Page",
      cvShowResults: "Show Results",
      cvExamIsPaid: "Quiz Is Paid ?",
      // cvExamIsOneTime: "Quiz Is One Time",
      cvExamImage: "Quiz Image",
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "App",
      QuizType: QuizType,
      vmQuizFormData: {},
      vmqbkExamCategoryList: " ",
      vmStartDate: null,
      minStartDate: null,
      showToast: false,
      cvPBACoverPic: "Edit Quiz Picture",
      vmCoverPic: "",
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      cvImageExtensionErrorMsg: " Only jpeg, webp and png is supported.",
      cvImageSizeErrorMsg: " Image size must be less than 4 mb.",
      updateImageType: null,
      vmProfilePic: "",
      app_id: "",
      cvCoverPicLoading: false,
      cvCoverPicError: false,
      isPhotoUploaded: false,
      cvExamIsGidePlusOptions: [
        {
          name: "For All"
        },
        {
          name: "Gide+"
        }
      ],
      chainableApps: [],
      app_is_gideplus: "",
      app_is_one_time: false,
      app_is_paid: false,
      imageSize: "Size  1080 * 566",
      updateCategoryCount: 0
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    this.QuizType = this.QuizType()
    socialvue.index()
    this.QuizType = QuizType(this.userData.user_role)
    this.appView()
    this.appCategoryDistinct()
    this.getAppsOfType()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmQuizFormData) {
          if (!this.vmQuizFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * appView
     */
    async appView () {
      try {
        if (this.propOpenedInModal) {
          this.vmQuizFormData = this.propQuizObj
          // Quiz Images
          if (this.vmQuizFormData.app_image) {
            this.vmCoverPic = this.vmQuizFormData.app_image
          }

          this.vmQuizFormData.exam_category_option = this.vmQuizFormData.app_category
          this.vmQuizFormData.exam_date = moment(new Date().setTime(this.vmQuizFormData.app_date_ts * 1000)).format()
          this.app_is_gideplus = this.vmQuizFormData.app_is_gideplus == 1 ? { name: "Gide+" } : { name: "For All" }
          /** eslint disable-next-line **/
          this.app_is_one_time = this.vmQuizFormData.app_is_one_time == 1 ? true : false // eslint-disable-line
          this.app_is_paid = this.vmQuizFormData.app_is_paid == 1 ? true : false // eslint-disable-line
        }
        else {
          let quizId = this.$route.params.app_id
          let quizViewResp = await Apps.appView(this, quizId)

          if (quizViewResp && quizViewResp.resp_status) {
            this.vmQuizFormData = quizViewResp.resp_data.data
            this.vmQuizFormData.exam_category_option = quizViewResp.resp_data.data.app_category

            this.vmQuizFormData.exam_date = moment(new Date().setTime(this.vmQuizFormData.app_date_ts * 1000)).format()
            this.app_is_gideplus = this.vmQuizFormData.app_is_gideplus == 1 ? { name: "Gide+" } : { name: "For All" }
            /** eslint disable-next-line **/
            this.app_is_one_time = this.vmQuizFormData.app_is_one_time == 1 ? true : false // eslint-disable-line
            this.app_is_paid = this.vmQuizFormData.app_is_paid == 1 ? true : false // eslint-disable-line
            this.vmQuizFormData.app_type = quizViewResp.resp_data.data.app_type
          }
          // Quiz Images
          if (this.vmQuizFormData.app_image) {
            this.vmCoverPic = this.vmQuizFormData.app_image
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at appView() and Exception:", err.message)
      }
    },
    /**
     * appEdit
     */
    async appEdit () {
      try {
        this.cvLoadingStatus = true
        this.vmQuizFormData.app_date_ts = this.vmQuizFormData.exam_date ? (moment(this.vmQuizFormData.exam_date).valueOf() / 1000) : 0
        this.vmQuizFormData.app_is_gideplus = this.app_is_gideplus.name === "Gide+" ? 1 : 0
        this.vmQuizFormData.app_is_one_time = this.app_is_one_time === true ? 1 : 0
        this.vmQuizFormData.app_is_paid = this.app_is_paid === true ? 1 : 0

        if (this.vmQuizFormData.exam_category_option !== "AddNew") {
          this.vmQuizFormData.app_category = this.vmQuizFormData.exam_category_option
        }
        let quizAddResp = await Apps.appEdit(this, this.vmQuizFormData)
        await ApiResponse.responseMessageDisplay(this, quizAddResp)

        if (quizAddResp && !quizAddResp.resp_status) {
          return false
        }

        if (this.isPhotoUploaded) {
          let imageAddResp = await this.imageAdd(this.vmQuizFormData.app_id)
          if (imageAddResp && imageAddResp.resp_status) {
            quizAddResp.resp_data.app_image = imageAddResp.resp_data[0].image
          }
          this.isPhotoUploaded = false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseQuizEditModal", this.vmQuizFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at appEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * appCategoryDistinct
     */
    async appCategoryDistinct () {
      try {
        let qbkExamCategoryResp = await Apps.appCategoryDistinct(this)
        if (qbkExamCategoryResp && qbkExamCategoryResp.resp_status) {
          this.vmqbkExamCategoryList = qbkExamCategoryResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception occurred at appCategoryDistinct() and Exception:", err.message)
      }
    },
    /**
     * getAppsOfType
     * event_state: 0 - Published
     * event_state: 1 - Deleted
     * event_state: 2 - UnPublished
     */
    async getAppsOfType () {
      const filter = { event_state: "0|2" }
      const appsToBeChained = await Apps.appListConsole(this, filter)
      if (appsToBeChained.resp_status) {
        this.chainableApps = appsToBeChained.resp_data.data
      }
    },
    /**
     * openImageDialog
     *
     */
    openImageDialog (e, imageType) {
      this.isPhotoUploaded = true
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 4 * 1024 * 1024
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let orgImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": orgImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * initPBAData
     */
    initPBAData () {
      return {
        question_name: "",
        module_id: ""
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (appId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "APPS",
          "module_id": appId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Apps.imageAddToApp(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }

        this.vmPBACoverPicImage = Object.assign({}, this.initPBAData())
        imageObj = {}
        return imageUpdateResp
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
        return err
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    }
  }
}
</script>
